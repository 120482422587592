import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import db from './firebase';
import { collection, getDocs, addDoc, onSnapshot,  deleteDoc, doc, updateDoc  } from "firebase/firestore";
import { getStorage,ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styles from './Stuff.module.css';


const Stuff = () => {
  const [stuff, setStuff] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newStuffName, setNewStuffName] = useState("");
  const [newPrefabName, setNewPrefabName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [importCategory, setImportCategory] = useState("");
  const [showImportModal, setShowImportModal] = useState(false);

  const handleImportModalShow = () => setShowImportModal(true);
  const handleImportModalClose = () => setShowImportModal(false);
  

  const [editingItemId, setEditingItemId] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState("");


  useEffect(() => {
    const stuffCollection = collection(db, "stuffs");
  
    const unsubscribe = onSnapshot(stuffCollection, (snapshot) => {
      const stuffList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setStuff(stuffList);
    });
  
    // Détache l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const handleImageUpload = async (file) => {
    if (!file) return;
  
    const storage = getStorage();
    const storageRef = ref(storage, 'stuffs/' + file.name);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleAddStuff = async () => {
    try {
        const imageUrl = await handleImageUpload(imageFile); // imageFile est le fichier sélectionné par l'utilisateur

      await addDoc(collection(db, "stuffs"), {
        name: newStuffName,
        imageUrl: imageUrl || '',
        prefab: newPrefabName,
        category: selectedCategory
      });
      setNewStuffName("");
      setNewPrefabName("");
      handleModalClose();
      // Optionally, fetch stuff again to refresh the list
      // fetchStuff();
    } catch (error) {
      console.error("Error adding new stuff: ", error);
    }
  };

  const handleEditClick = (item) => {
    setEditingItemId(item.id);
    setNewStuffName(item.name);
    setNewPrefabName(item.prefab);
    setSelectedCategory(item.category);
    // setImageFile(null); // Gérer différemment si vous souhaitez permettre la modification de l'image existante
    setExistingImageUrl(item.imageUrl); // Stockez l'URL existante de l'image

    setShowModal(true); // Ouvre le modal pour l'édition
  };
  
  const handleUpdateStuff = async () => {
    try {
      const stuffDocRef = doc(db, "stuffs", editingItemId);
      let imageUrl = existingImageUrl; // Utilisez l'URL existante

        if (imageFile) {
        imageUrl = await handleImageUpload(imageFile); // Si une nouvelle image est téléversée, utilisez sa nouvelle URL
        }
      await updateDoc(stuffDocRef, {
        name: newStuffName,
        prefab: newPrefabName,
        category: selectedCategory,
        imageUrl: imageUrl
       });
  
      handleModalClose();
      resetForm();
    } catch (error) {
      console.error("Error updating stuff: ", error);
    }
  };
  
  const handleDeleteStuff = async (id) => {
    try {
      const stuffDoc = doc(db, "stuffs", id);
      await deleteDoc(stuffDoc);
    } catch (error) {
      console.error("Error deleting stuff: ", error);
    }
  };
  const resetForm = () => {
    setNewStuffName("");
    setNewPrefabName("");
    setSelectedCategory("");
    setImageFile(null);
    setEditingItemId(null);
  };

  const handleFileSelect = (event) => {
    setSelectedFiles([...event.target.files]);
  };
  const handleUploadAndCreateItems = async () => {
    for (let file of selectedFiles) {
      try {
        const imageUrl = await handleImageUpload(file); // Fonction existante pour le téléversement
        const itemName = file.name.split('.')[0]; // Utilisez le nom du fichier comme nom de l'item
  
        await addDoc(collection(db, "stuffs"), {
          name: itemName,
          imageUrl: imageUrl,
          prefab: itemName,
          category: "Default" // Ou toute autre catégorie par défaut
        });
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }
  
    setSelectedFiles([]); // Réinitialiser après l'import
  };
  return (
    <div className="container mt-5">
        <div className='row'>
            <div className='col-6 col-md-4 col-lg-3 mx-auto'>
                <img src='https://firebasestorage.googleapis.com/v0/b/arfight-f2dad.appspot.com/o/Fichier%201.png?alt=media&token=97a548fc-7df7-42ed-b733-8583b521df93' className='w-100'></img>
            </div>
        </div>
        <div className='row mb-4'>
            <div className='col-12'>
              <h1>Game Stuff</h1>
            </div>
            
        </div>
        <div className='row'>
              <div className='col-12 col-md-6'>
                  <Button variant="primary" onClick={handleModalShow}>
                      Add New Stuff
                  </Button>
              </div>
              <div className='col-12 col-md-6'>
                <Button variant="primary" onClick={handleImportModalShow}>Importer des Items</Button>
              </div>
          </div>
          
        
      <Table striped bordered hover className='mt-5'>
        <thead>
            <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Prefab</th>
            <th>Actions</th>
            <th>Category</th>
            </tr>
        </thead>
        <tbody>
            {stuff.map(item => (
            <tr key={item.id}>
                <td>
                  <div className="image-container">
                    <img src={item.imageUrl} alt={item.name} />
                  </div>
                </td>
                <td className='align-middle'>{item.name}</td>
                <td className='align-middle'>{item.prefab}</td>
                <td className='align-middle'>{item.category}</td>
                <td>
                    <Button variant="warning" size="sm" className='me-2' onClick={() => handleEditClick(item)}>
                        Edit
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => handleDeleteStuff(item.id)}>
                        Delete
                    </Button>
                </td>
            </tr>
            ))}
        </tbody>
        </Table>


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
            <Modal.Title>{editingItemId ? "Edit Stuff" : "Add New Stuff"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Stuff Name</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter stuff name" 
                value={newStuffName} 
                onChange={(e) => setNewStuffName(e.target.value)} 
              />
            <Form.Group>
            </Form.Group>
                <Form.Label>Name of prefab</Form.Label>
                    <Form.Control 
                    type="text" 
                    placeholder="Enter prefab name" 
                    value={newPrefabName} 
                    onChange={(e) => setNewPrefabName(e.target.value)} 
                    />
            </Form.Group>

            <Form.Group>
                <Form.Label>Preview prefab</Form.Label>
                <Form.Control 
                type="file" 
                onChange={(e) => setImageFile(e.target.files[0])} 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Catégorie</Form.Label>
                <Form.Select 
                    value={selectedCategory} 
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    <option value="">Sélectionner une catégorie</option>
                    <option value="WEAPON">WEAPON</option>
                    <option value="HAT">HAT</option>
                    <option value="HEADARMOR">HEADARMOR</option>
                    <option value="ARMOR">ARMOR</option>
                    <option value="SHIELD">SHIELD</option>
                    <option value="HEAD">HEAD</option>
                    <option value="HAIR">HAIR</option>
                    <option value="HORN">HORN</option>
                    <option value="GLASSE">GLASSE</option>
                    <option value="MOUSTACHE">MOUSTACHE</option>
                    <option value="MOUTH">MOUTH</option>
                    <option value="BACKPACK">BACKPACK</option>
                    <option value="EYE">EYE</option>
                    <option value="HEAD">HEAD</option>
                </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={editingItemId ? handleUpdateStuff : handleAddStuff}>
            {editingItemId ? "Edit Stuff" : "Add New Stuff"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={handleImportModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import de Masse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Catégorie</Form.Label>
              <Form.Select value={importCategory} onChange={(e) => setImportCategory(e.target.value)}>
                  <option value="">Sélectionner une catégorie</option>
                    <option value="WEAPON">WEAPON</option>
                    <option value="HAT">HAT</option>
                    <option value="HEADARMOR">HEADARMOR</option>
                    <option value="ARMOR">ARMOR</option>
                    <option value="SHIELD">SHIELD</option>
                    <option value="HEAD">HEAD</option>
                    <option value="HAIR">HAIR</option>
                    <option value="HORN">HORN</option>
                    <option value="GLASSE">GLASSE</option>
                    <option value="MOUSTACHE">MOUSTACHE</option>
                    <option value="MOUTH">MOUTH</option>
                    <option value="BACKPACK">BACKPACK</option>
                    <option value="EYE">EYE</option>
                    <option value="HEAD">HEAD</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Choisir des Images</Form.Label>
              <Form.Control type="file" multiple onChange={handleFileSelect} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleImportModalClose}>Fermer</Button>
          <Button variant="primary" onClick={handleUploadAndCreateItems}>Importer les Images</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Stuff;
