import logo from './logo.svg';
import './App.css';
import Stuff from './Stuff';

function App() {
  return (
    <div className="App">
          <Stuff />
    </div>
  );
}

export default App;
