import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBNTu45XKAWF2kjpw3cEQd4xAakiAwIvxI",
  authDomain: "arfight-f2dad.firebaseapp.com",
  databaseURL: "https://arfight-f2dad-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "arfight-f2dad",
  storageBucket: "arfight-f2dad.appspot.com",
  messagingSenderId: "860703712620",
  appId: "1:860703712620:web:a8794138ccdb327f777f49",
  measurementId: "G-YY5LQZVZFH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firestore instance
const db = getFirestore(app);

export default db;
